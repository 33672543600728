<template>
  <LoadingSpinner :showLoading="!initialized" />
  <ScrollPanel class='news-feed-scrollpanel' v-show="initialized">
    <div id="markNews"
      data-qmod-tool="litenews"
      :data-qmod-params='newsParams'
      class="news-qtool">
    </div >
  </ScrollPanel>
</template>

<script>

import LoadingSpinner from '../common/LoadingSpinner.vue';
import ScrollPanel from 'primevue/scrollpanel';
import SecurityFormatter from '../../common/SecurityFormatter';

export default {
    name: 'MarketNews',
    props: {
        selectedEquity: {
          type: Object,
          default: null
        }
    },

    computed : {
        newsParams() {
          if (this.selectedEquity) {
            let symbolToUse = this.selectedEquity.symbol+SecurityFormatter.getQMExchangeSymbolSuffix(this.selectedEquity.exchangeSymbol) ;
            if( symbolToUse.endsWith(":AQL") ) {
                symbolToUse = this.selectedEquity.proxySymbol.replace('/', '.');
            }
            return '{"lang":"en","resultsPerPage":25,"noSrc":"' + this.excludedSources + '","videonews":false,"showVideos":false,"socialshare":true,"showSharingButtons":false,"dateHeadings":true,"showRefresh":true,"symbol":"' 
              + symbolToUse + '"}';
          } else {
            return '{"lang":"en","resultsPerPage":15,"noSrc":"' + this.excludedSources 
              + '","videonews":false,"showVideos":false,"socialshare":true,"showSharingButtons":false,"dateHeadings":true,"showRefresh":true,"symbol":"' + this.defaultTopics + '"}';
          }
        }
    },
   components: {
     ScrollPanel, LoadingSpinner
    
   },
    activated(){
      if (!this.justMounted) {
        window.newsQMod.loadTools(); 
      }
      this.justMounted = false;
    },
    deactivated() {
      this.justMounted = false;
    },
    mounted() {
      this.justMounted = true;  
      let tmplEle = document.createElement('script');
      tmplEle.setAttribute("type", "text/template");
      let txt = document.createTextNode(  
        `<div class="qmod-ui-tool qmod-litenews">
          <div class="qmod-modal-overlay" rv-show="modal.show">
            <div class="qmod-modal-overlay" rv-show="modal.show">
              <div class="qmod-ui-modal">
                <div class="qmod-ui-modal-titlebar">
                  <div class="qmod-ui-modal-title" rv-html="modal.qmstory.datetime | momentjs true 'LLL'"></div>
                  <button class="qmod-btn qmod-modal-close" rv-on-click="modal.close"><i class="fa fa-times"></i></button>
                  <div class="qmod-titlebar-buttons" rv-show="modal.socialshare"><button class="qmod-btn qmod-btn-email" rv-show="modal.showSharingButtons" rv-on-click="modal.emailStory"><span class="fa fa-envelope-o"></span></button><button class="qmod-btn qmod-btn-print" rv-on-click="modal.print"><span class="fa fa-print"></span></button><button class="qmod-btn qmod-btn-copy"><span class="fa fa-copy"></span></button><button class="qmod-btn qmod-sharesocial-facebook" rv-show="modal.showSharingButtons" rv-on-click="modal.shareSocialFacebook"><span class="fa fa-facebook"></span></button><button class="qmod-btn qmod-sharesocial-twitter" rv-show="modal.showSharingButtons" rv-on-click="modal.shareSocialTwitter"><i class="fa fa-twitter"></i></button><button class="qmod-btn qmod-sharesocial-linkedin" rv-show="modal.showSharingButtons" rv-on-click="modal.shareSocialLinkedIn"><i class="fa fa-linkedin"></i></button><button class="qmod-btn qmod-font-grow" rv-on-click="modal.growFont"><span class="fa fa-font"></span></button><button class="qmod-btn qmod-font-shrink" rv-on-click="modal.shrinkFont"><span class="fa fa-font"></span></button></div>
                </div>
                <div class="qmod-ui-modal-body" rv-style-height="data.binders.storyHeight">
                  <div class="qmod-container qmod-headlines-story">
                    <h3 rv-html="modal.qmstory.qmheadline"></h3>
                    <h4 rv-if="modal.qmsubheadline" rv-html="modal.qmsubheadline"></h4>
                    <div class="qmod-story" rv-html="modal.qmstory.qmtext"></div>
                    <span class="qmod-story-source">News Provided by {modal.source | str_replace '>' ''}</span><br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="qmod-block-wrapper qmod-headlines-topics">
            <div class="qmod-news-output">
              <div class="qmod-nonvideo-news-column">
                <div class="qmod-tool-wrap" rv-unless="data.newsData.news.0">
                  <div class="qmod-block-wrapper">
                    <div class="qmod-invalid">{tplLang.nonews | toLang tLang} {data.binders.topic}</div>
                  </div>
                </div>
                <div class="qmod-loader-class" rv-show="scopez.loading">
                  <div class="qmod-loader-threedot"></div>
                </div>
                <div rv-unless="scopez.tiled">
                  <div rv-if="data.newsData.news.0" rv-hide="scopez.loading">
                    <ul class="qmod-news-list" rv-each-item="data.newsData.news.0.newsitem | groupby 'date' | propertyList" rv-class-qmod-show-thumbnails="scopez.showThumbnail" rv-class-qmod-no-date-headings="scopez.dateHeadings | nq true">
                      <h3 class="qmod-heading qmod-hbg" rv-addclass="item.key | preFix &quot;qmod-date-&quot;">{item.key | momentjs true 'ddd MMM Do, YYYY' tLang}</h3>
                      <li class="qmod-newsitem qmod-item-untiled" rv-each-itemz="item.value" rv-addclass="itemz.newsid | preFix &quot;qmod-newsid-&quot;">
                        <span class="qmod-news-source" rv-unless="scopez.showThumbnail">{itemz.source}</span><span class="qmod-news-date" rv-unless="scopez.showThumbnail" rv-show="scopez.dateHeadings" rv-html="itemz.datetime | momentjs true 'LT' tLang"></span><span class="qmod-news-datetime" rv-unless="scopez.showThumbnail" rv-hide="scopez.dateHeadings" rv-html="itemz.datetime | momentjs true 'lll' tLang"></span><span class="qmod-news-symbols" rv-if="scopez.showSymbol"><span class="qmod-news-symbol" rv-each-newssymbol="itemz.qmtopics.qmsymbol" rv-on-click="scopez.clickSymbol"><span rv-qmod-news-symbol="newssymbol"></span></span></span>
                        <a class="qmod-headline" rv-unless="scopez.showThumbnail" rv-data-storyid="itemz.newsid" rv-on-click="data.loadNewsStory" tabindex="0">
                          <h4 rv-html="itemz.headline"></h4>
                        </a>
                        <div class="qmod-news-with-thumbnail" rv-if="scopez.showThumbnail">
                          <div class="qmod-news-column-left">
                            <div class="qmod-news-date-container"><span class="qmod-news-date" rv-show="scopez.dateHeadings" rv-html="itemz.datetime | momentjs true 'LT' tLang"></span><span class="qmod-news-datetime" rv-hide="scopez.dateHeadings" rv-html="itemz.datetime | momentjs true 'lll' tLang"></span></div>
                            <div class="qmod-headline-container">
                              <div class="qmod-news-headline">
                                <a class="qmod-headline" rv-data-storyid="itemz.newsid" rv-on-click="data.loadNewsStory">
                                  <h4 rv-html="itemz.headline">
                                    <div class="qmod-news-loader-story anim-spin fa fa-spinner" style="display:none;"></div>
                                  </h4>
                                </a>
                              </div>
                              <div class="qmod-news-summary" rv-if="scopez.showSummary" rv-html="itemz.qmsummary"></div>
                              <div class="qmod-news-source"><span class="qmod-news-source">{itemz.source}</span></div>
                            </div>
                          </div>
                          <div class="qmod-news-column-right">
                            <div class="qmod-news-thumbnail-container">
                              <div class="qmod-news-thumbnail" rv-if="itemz.thumbnailurl" rv-img-bg="itemz.thumbnailurl"></div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="qmod-tiled-list" rv-if="scopez.tiled">
                  <div rv-if="data.newsData.news.0" rv-hide="scopez.loading">
                    <span class="qmod-news-list" rv-each-item="data.newsData.news.0.newsitem | groupby 'date' | propertyList" rv-class-qmod-show-thumbnails="scopez.showThumbnail" rv-class-qmod-no-date-headings="scopez.dateHeadings | nq true" rv-ifclass="scopez.singleLineSource" data-qmod-false-class="qmod-single-line">
                      <h3 class="qmod-heading qmod-hbg" rv-unless="scopez.singleLineSource" rv-addclass="item.key | preFix &quot;qmod-date-&quot;">{item.key | momentjs true 'ddd MMM Do, YYYY' tLang}</h3>
                      <li class="qmod-newsitem qmod-item-tiled pure-u-lg-1-2" rv-each-itemz="item.value" rv-addclass="itemz.newsid | preFix &quot;qmod-newsid-&quot;">
                        <div class="qmod-news-tile">
                          <a class="qmod-headline" rv-data-storyid="itemz.newsid" rv-on-click="data.loadNewsStory" tabindex="0">
                            <h4>
                              <span rv-ifclass="scopez.showThumbnail" data-qmod-true-class="pure-u-3-4 pure-u-sm-7-8"><span rv-html="itemz.headline"></span></span>
                              <span rv-ifclass="scopez.showThumbnail" data-qmod-true-class="pure-u-1-4 pure-u-sm-1-8">
                                <span class="qmod-news-with-thumbnail">
                                  <div class="qmod-news-column-right">
                                    <div class="qmod-news-thumbnail-container">
                                      <div class="qmod-news-thumbnail" rv-if="itemz.thumbnailurl" rv-img-bg="itemz.thumbnailurl"></div>
                                    </div>
                                  </div>
                                </span>
                              </span>
                            </h4>
                          </a>
                          <span class="qmod-news-date-date" rv-if="scopez.singleLineSource" rv-addclass="item.key | preFix &quot;qmod-date-&quot;">{item.key | momentjs true 'ddd MMM Do, YYYY' tLang} &nbsp;</span><span class="qmod-news-date" rv-show="scopez.dateHeadings" rv-html="itemz.datetime | momentjs true 'LT' tLang">&nbsp;</span><span class="qmod-news-datetime" rv-hide="scopez.dateHeadings" rv-html="itemz.datetime | momentjs true 'lll' tLang"> </span>&nbsp;<span class="qmod-news-source">{itemz.source}</span>
                          <div class="qmod-news-symbols" rv-if="scopez.showSymbol"><span class="qmod-news-symbol" rv-each-newssymbol="itemz.qmtopics.qmsymbol" rv-on-click="scopez.clickSymbol"><span rv-qmod-news-symbol="newssymbol"></span></span></div>
                        </div>
                      </li>
                    </span>
                  </div>
                </div>
                <div class="qmod-paginator qmod-pagin-bottom" rv-if="data.newsData.pagenumber">
                  <div class="qmod-loader-class" rv-show="scopez.loadingupdate" rv-unless="scopez.loading">
                    <div class="qmod-loader-threedot qmod-loader-threedot-small"></div>
                  </div>
                  <span class="qmod-more-news" rv-unless="scopez.nomorenews" rv-show="data.newsData.news.0"><button class="qmod-btn qmod-more-news-btn" rv-on-click="data.binders.updateNews" tabindex="0" rv-ifclass="scopez.loadingupdate" data-qmod-true-class="qmod-disabled"><span class="qmod-more-news-text" rv-unless="data.binders.moreNewsOptionalText" rv-hide="scopez.loadingupdate" rv-html="tplLang.morenews | toLang tLang"></span><span class="qmod-more-news-optional-text" rv-if="data.binders.moreNewsOptionalText" rv-hide="scopez.loadingupdate" rv-html="data.binders.moreNewsOptionalText"></span><span class="qmod-more-news-chevron fa fa-chevron-down" rv-if="data.binders.moreNewsToggle" rv-hide="scopez.loadingupdate"></span><span class="qmod-loading-text" rv-show="scopez.loadingupdate" rv-html="tplLang.loading | toLang tLang"></span></button><button class="qmod-btn qmod-more-news-btn" rv-if="data.binders.moreNewsToggle" rv-show="data.newsData.news.0.newsitem | asArrayLength | &gt; scopez.resultsPerPage" rv-on-click="data.binders.showLess" tabindex="0" rv-ifclass="scopez.loadingupdate" data-qmod-true-class="qmod-disabled"><span class="qmod-more-news-text" rv-hide="scopez.loadingupdate" rv-html="tplLang.showlessinfo | toLang tLang"></span><span class="qmod-more-news-chevron fa fa-chevron-up" rv-hide="scopez.loadingupdate"></span><span class="qmod-loading-text" rv-show="scopez.loadingupdate" rv-html="tplLang.loading | toLang tLang"></span></button></span><span class="qmod-more-news" rv-if="scopez.nomorenews"><span rv-unless="scopez.customEndMsg">{'No more news found.' | i8ln tLang | upper} </span><span rv-if="scopez.customEndMsg">{scopez.customEndMsg}</span></span>
                </div>
              </div>
              <div class="qmod-video-news-column" rv-show="scopez.showvideos" rv-if="scopez.videos | asArrayLength | &gt;= 1">
                <h2 class="qmod-heading qmod-hbg">{tplLang.videonews | toLang tLang}</h2>
                <div class="qmod-container qmod-headlines-videos">
                  <div class="qmod-video-news-list">
                    <ul class="qmod-news-videos">
                      <li class="qmod-news-video" rv-each-newsvideo="scopez.videos" rv-data-storyid="newsvideo.newsid" rv-on-click="data.loadNewsStory" tabindex="0">
                        <div class="qmod-multi-video">
                          <div class="qmod-video-box"><img class="qmod-video-thumb" rv-src="newsvideo.videoimageurl"/></div>
                          <div class="qmod-video-title"><a class="qmod-video-link">{newsvideo.headline}</a></div>
                          <div class="qmod-video-details">
                            <div class="qmod-video-src">{newsvideo.source}</div>
                            <div class="qmod-video-date">{newsvideo.datetime | momentjs true 'MMM Do, YYYY' tLang}</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="qmod-popup-wrapper qmod-news-sources-modal" rv-show="scopez.showingNews">
            <div class="pop-up-middle">
              <div class="qmod-popup">
                <div class="qmod-popup-title">
                  {'Select News Sources' | i8ln tLang}
                  <div class="qmod-popup-titlebar-buttons"><button class="qmod-btn" rv-on-click="scopez.hideNewsSources" tabindex="0">&times;</button></div>
                </div>
                <div class="qmod-popup-body">
                  <div class="qmod-new-sources-head">
                    <div class="pure-u-1-2">
                      <div class="qmod-news-modal-head">{'Select News Sources' | i8ln tLang}</div>
                    </div>
                    <div class="pure-u-1-2 qmod-newsToggle"><span>{'Select' | i8ln tLang}:&nbsp;</span><a class="qmod-disable-all-contacts" rv-on-click="scopez.enableAllNews" tabindex="0">{'ALL' | i8ln tLang | upper}</a><span>&nbsp;/&nbsp;</span><a class="qmod-enable-all-contacts" rv-on-click="scopez.disableAllNews" tabindex="0">{'NONE' | i8ln tLang}</a></div>
                  </div>
                  <div class="qmod-news-sources">
                    <div class="qmod-source-row" rv-each-source="scopez.newsSources.all"><input class="qmod-chkrad" type="checkbox" rv-data-id="source.productCode" rv-on-change="scopez.toggleSourceActive" rv-id="source.productCode"/><label class="qmod-chkradlabel qmod-chkradlabel-brown" rv-for="source.productCode">{source.displayname}</label></div>
                  </div>
                </div>
                <div class="qmod-alertpopup-footer">
                  <div class="qmod-popupbtns"><span class="qmod-btn qmod-btn-sm qmod-btn-go qmod-darkblue" rv-on-click="scopez.updateSources" tabindex="0">{'showresults' | i8ln tLang}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>`);
      tmplEle.appendChild(txt);

      let markNewsDiv = document.getElementById('markNews');
      markNewsDiv.appendChild(tmplEle);
      markNewsDiv.addEventListener('qmod-after-template-bind',  () => {
          console.debug('marketnews template bound**************')
          this.initialized = true;
          
          
      }, false);
      window.qMod.loadTool('markNews'); 
       
    },
    data() {
      return {
          initialized: false,
          justMounted: false,
          excludedSources: "fool,foolcan,pen,psn,mjs,canw,prn,seek,pzo,nwd,cnw,glpr,nwmw,nnw,abnw,bayaw,bayawia,TheNewsWire,bwi",
          defaultTopics: "BANKFINA,CRYPTOC,BONDS001,BUSINESS,MARKECON,MUTUALFD"
      }
    },

   
}
</script>

<style scoped>
*:not(i), ::v-deep(*:not(i)) {
  font-family: "Trebuchet MS", "Verdana";
}

.news-feed-scrollpanel {
	width: 100%;
	height: 100%;
}
.news-feed-scrollpanel ::v-deep(.p-scrollpanel-content) {
	padding: 0 15px 0 0;
    overflow: auto;
}
.news-feed-scrollpanel ::v-deep(.p-scrollpanel-wrapper) {
	border-right: 9px solid #F2F2F2;
}
.news-feed-scrollpanel ::v-deep(.p-scrollpanel-bar) {
	background-color: #BFBFBF;
	opacity: 1;
	transition: background-color .2s;
}

::v-deep(.qmod-litenews .qmod-nonvideo-news-column ) {
  flex: auto;
  padding: 0px;
  margin: 0px;
}

::v-deep(a.qmod-dropdown_toggle.qmod-dropdown-sm) {
   /* background-color: #0D3349 !important; */
   /* background-color:#0a64a6 !important; */
   /* color: #d1d4dc; */
    border-radius: 5px;
    padding: 4px 5px;
}
::v-deep(.qmod-hbg){
  background-color: none;
  background: none;
}
::v-deep(.qmod-btn.qmod-btn-link) {
  border: none;
  color: #32364e;
  float: right;
  padding: 0px 8px 0px 0px;
}
::v-deep(.qmod-btn.qmod-btn-link:hover) {
  color: #32364e;
  background: none;
}
::v-deep(.qmod-btn.qmod-btn-link:focus) {
  border: none !important;
}


::v-deep(.qmod-btn) {
  background-color: #33CC99;
  color: #ffffff;
}

::v-deep(.qmod-btn:hover) {
  background-color: #3dba90;
  color: #ffffff;
}


::v-deep(.qmod-btn.qmod-more-news-btn) {
  text-decoration: underline;
  color: #32364e;
  background: none;
}
::v-deep(.qmod-btn.qmod-more-news-btn:hover) {
  color: #32364e;
}

::v-deep(.qmod-ui-tool a) {
  color: black;
}
::v-deep(.qmod-ui-tool a:hover) {
  /* color: #495057; */
  color: #33CC99;
}

::v-deep(.qmod-litenews .qmod-news-list) {
  margin: 0px;
}

::v-deep(.qmod-litenews .qmod-news-list li .qmod-headline > h4) {
  font-size: 14px;
  margin-top: 4px;
}
::v-deep(.qmod-litenews .qmod-news-list li .qmod-news-date),
::v-deep(.qmod-litenews .qmod-news-list li .qmod-news-source) {
  font-size: 12px;
}

::v-deep(h3.qmod-heading){
  font-size: 16px;
  font-weight: bold;
  color: #32364e;
  padding: 16px 0px 8px 16px;
}
::v-deep(h3.qmod-heading:first){
  padding: 0px 0px 8px 16px;
}

::v-deep(a.qmod-headline) {
   /* color: #d1d4dc; */
   text-decoration: none;
}
::v-deep(a.qmod-headline:hover) {
   color:#33CC99;

}

::v-deep(.qmod-litenews .qmod-news-list li){
  padding: 8px 8px 8px 16px;
}

::v-deep(.qmod-litenews .qmod-news-list li .qmod-news-date) {
  padding-right: 0;
  margin-right: 0;
  color: #7F7F7F;
}

::v-deep(.qmod-litenews .qmod-ui-modal) {
      /* background-color: #1f2d40; */
    /* border-color: #17212f; */
    border-radius: 4px;
    /* max-height: 60vh;*/
    max-width: 60vw; 
    overflow:hidden;
}

::v-deep(.qmod-ui-modal .qmod-ui-modal-titlebar) {
  background: none;
  border-color: black;
}
::v-deep(.qmod-ui-modal .qmod-ui-modal-titlebar .qmod-ui-modal-title) {
  color: #32364e;
  font-size: 24px;
  font-weight: bold;
}

::v-deep(.qmod-litenews .qmod-ui-modal-body) {
  max-height: calc(100vh - 40px);
}

::v-deep(.qmod-litenews .qmod-titlebar-buttons .qmod-btn){
  background: none;
  color: #32364e;
  border: none !important;

}

::v-deep(.qmod-litenews .qmod-titlebar-buttons .qmod-btn:hover){
  background: #32364e;
  color: #fff;
}

::v-deep(.qmod-litenews .qmod-titlebar-buttons .qmod-btn:focus){
   /* border: 1px solid #304562 !important; */
/* background-color: #1f2d40 !important; */
   /* color: #d1d4dc !important; */

}
 
::v-deep(.qmod-litenews .qmod-ui-modal .qmod-btn.qmod-modal-close) {
    /* border-radius: 50px; */
    /* background: #33CC99; */
    border: none;
    width: 25px;
    height: 25px;
    background-color: #fff;
    color: #32364e;
}

/* ::v-deep(.qmod-litenews .qmod-ui-modal .qmod-btn.qmod-modal-close:hover) {
    background: #00C083;
    border-color: #00C083;
}
::v-deep(.qmod-litenews .qmod-ui-modal .qmod-btn.qmod-modal-close:focus) {
    background: #00C083;
    border-color: #00C083;
} */

::v-deep(.qmod-litenews .qmod-ui-modal .qmod-btn.qmod-modal-close > i) {
   font-size:1.5rem;
}

::v-deep(.qmod-container.qmod-headlines-story) {
  /* color: #d1d4dc; */
}
::v-deep(.qmod-litenews .qmod-news-output){
  margin: 0px;
  padding: 8px 0px;
}

::v-deep(.qmod-litenews .qmod-headlines-videos) {
  padding: 0px;
  margin: 0px;
}

::v-deep(.qmod-litenews .qmod-multi-video) {
  padding: 0px;
}

::v-deep(.qmod-litenews .qmod-video-title) {
  margin: 0px;
  font-size: 14px;
  color: black;
}

::v-deep(.qmod-litenews .qmod-multi-video .qmod-video-details .qmod-video-date) {
  padding-left: 8px;
  color: #7F7F7F;
}

@media (max-width: 760px) {

  ::v-deep(.qmod-litenews .qmod-ui-modal) {
  
    max-width: 100%; 
  }

  ::v-deep(.qmod-btn.qmod-btn-link) {
    position: relative;
    right: 16px;
  }

.p-scrollpanel.p-component {
  /* .news-feed-scrollpanel ::v-deep(.p-scrollpanel.p-component) { */
    
    height: 80vh !important;
  }
  
  .news-feed-scrollpanel ::v-deep(.p-scrollpanel-content) {
    padding-bottom: 30px;
    
  }
  .news-feed-scrollpanel ::v-deep(.p-scrollpanel-wrapper) {
    border-right: none;
    padding: 5px;
    /* height: 95vh; */
  }

  ::v-deep(.qmod-ui-modal) {
    margin: 65px 0px 0px 0px;
    padding: 5px;
    
  }

  ::v-deep(.qmod-litenews .qmod-ui-modal-body) {
     max-height: calc(100vh - 200px);
     /* padding-bottom: 10px;  */
     
   }

  ::v-deep(.qmod-ui-modal-titlebar){
    border: 0;
  }
 
  ::v-deep(.p-scrollpanel-bar.p-scrollpanel-bar-y) {
    opacity: 0;
  }
}
</style>